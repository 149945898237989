<template>
  <div class="holder" :class="{mini: isMini}">
    <audio ref="player" :src="currentSrc"
    @durationchange="durationChange"
    @timeupdate="progress"
    @canplaythrough="canPlay"
    @ended="ended"></audio>
    <img v-if="!isMini" class="cover" src="/img/EPCover.png">
    <div class="controls" :class="{bg:!isMini}">
        <div v-if="isMini" class="mini-cover">
          <img src="/img/EPCover.png">
        </div>
        <button class="play" @click="togglePlay">
          <svg v-if="!isPLaying" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools --> <title>ic_fluent_play_48_filled</title> <desc>Created with Sketch.</desc> <g id="🔍-Product-Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"> <g id="ic_fluent_play_48_filled" fill="#CCCCCC" fill-rule="nonzero"> <path d="M13.7501344,8.41212026 L38.1671892,21.1169293 C39.7594652,21.9454306 40.3786269,23.9078584 39.5501255,25.5001344 C39.2420737,26.0921715 38.7592263,26.5750189 38.1671892,26.8830707 L13.7501344,39.5878797 C12.1578584,40.4163811 10.1954306,39.7972194 9.36692926,38.2049434 C9.12586301,37.7416442 9,37.2270724 9,36.704809 L9,11.295191 C9,9.50026556 10.4550746,8.045191 12.25,8.045191 C12.6976544,8.045191 13.1396577,8.13766178 13.5485655,8.31589049 L13.7501344,8.41212026 Z" id="🎨-Color"> </path> </g> </g> </g></svg>
          <svg v-if="isPLaying" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools --> <title>ic_fluent_pause_48_filled</title> <desc>Created with Sketch.</desc> <g id="🔍-Product-Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"> <g id="ic_fluent_pause_48_filled" fill="#CCCCCC" fill-rule="nonzero"> <path d="M17.75,7 C19.5449254,7 21,8.45507456 21,10.25 L21,37.75 C21,39.5449254 19.5449254,41 17.75,41 L12.25,41 C10.4550746,41 9,39.5449254 9,37.75 L9,10.25 C9,8.45507456 10.4550746,7 12.25,7 L17.75,7 Z M35.75,7 C37.5449254,7 39,8.45507456 39,10.25 L39,37.75 C39,39.5449254 37.5449254,41 35.75,41 L30.25,41 C28.4550746,41 27,39.5449254 27,37.75 L27,10.25 C27,8.45507456 28.4550746,7 30.25,7 L35.75,7 Z" id="🎨-Color"> </path> </g> </g> </g></svg>
        </button>
        <button class="prev" @click="prev">
          <svg viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools --> <title>ic_fluent_arrow_previous_24_filled</title> <desc>Created with Sketch.</desc> <g id="🔍-Product-Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"> <g id="ic_fluent_arrow_previous_24_filled" fill="#CCCCCC" fill-rule="nonzero"> <path d="M6,3 C5.48716416,3 5.06449284,3.38604019 5.00672773,3.88337887 L5,4 L5,20 C5,20.5522847 5.44771525,21 6,21 C6.51283584,21 6.93550716,20.6139598 6.99327227,20.1166211 L7,20 L7,4 C7,3.44771525 6.55228475,3 6,3 Z M18.7071068,3.29289322 C18.3466228,2.93240926 17.7793918,2.90467972 17.3871006,3.20970461 L17.2928932,3.29289322 L9.29289322,11.2928932 C8.93240926,11.6533772 8.90467972,12.2206082 9.20970461,12.6128994 L9.29289322,12.7071068 L17.2928932,20.7071068 C17.6834175,21.0976311 18.3165825,21.0976311 18.7071068,20.7071068 C19.0675907,20.3466228 19.0953203,19.7793918 18.7902954,19.3871006 L18.7071068,19.2928932 L11.4142136,12 L18.7071068,4.70710678 C19.0976311,4.31658249 19.0976311,3.68341751 18.7071068,3.29289322 Z" id="🎨-Color"> </path> </g> </g> </g></svg>
        </button>
        <button class="next" @click="next">
          <svg viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools --> <title>ic_fluent_arrow_next_24_filled</title> <desc>Created with Sketch.</desc> <g id="🔍-Product-Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"> <g id="ic_fluent_arrow_next_24_filled" fill="#CCCCCC" fill-rule="nonzero"> <path d="M18,3 C18.5128358,3 18.9355072,3.38604019 18.9932723,3.88337887 L19,4 L19,20 C19,20.5522847 18.5522847,21 18,21 C17.4871642,21 17.0644928,20.6139598 17.0067277,20.1166211 L17,20 L17,4 C17,3.44771525 17.4477153,3 18,3 Z M5.29289322,3.29289322 C5.65337718,2.93240926 6.22060824,2.90467972 6.61289944,3.20970461 L6.70710678,3.29289322 L14.7071068,11.2928932 C15.0675907,11.6533772 15.0953203,12.2206082 14.7902954,12.6128994 L14.7071068,12.7071068 L6.70710678,20.7071068 C6.31658249,21.0976311 5.68341751,21.0976311 5.29289322,20.7071068 C4.93240926,20.3466228 4.90467972,19.7793918 5.20970461,19.3871006 L5.29289322,19.2928932 L12.5857864,12 L5.29289322,4.70710678 C4.90236893,4.31658249 4.90236893,3.68341751 5.29289322,3.29289322 Z" id="🎨-Color"> </path> </g> </g> </g></svg>
        </button>
        <div class="progress-holder">
          <div v-if="isMini" class="current-title">{{ currentTrack + 1 }}. {{ currentTitle }} ({{ currentDuration }})</div>
          <div class="progress" @click="setSongPosition">
            <div class="elapsed" :style="{width: currentElapsed}"></div>
          </div>
        </div>
    </div>
    <div v-if="!isMini" class="playlist">
      <ul>
        <li v-for="(track, i) in songs" :key="i" class="bg" :class="{active: currentTrack == i}" @click="playTrack(i)">
          <span class="icon">
            <EqIcon v-if="isPLaying && currentTrack == i"/>
          </span>
          <span class="title">{{ i + 1 }}. {{ track.title }}</span>
          <span class="lenth">{{ track.length }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, Ref, ref, defineProps } from 'vue'
import EqIcon from './eq-icon.vue'

const props = defineProps({
  isMini: Boolean
})

const songs = [
  { title: 'Rising (instrumental demo)', length: '2:15', file: '1.Rising.mp3' },
  { title: 'Time (instrumental demo)', length: '4:40', file: '2.Time.mp3' },
  { title: 'The Journey (instrumental demo)', length: '4:53', file: '3.TheJourney.mp3' },
  { title: 'Haunted (instrumental demo)', length: '4:40', file: '4.Haunted.mp3' },
  { title: 'Passed (instrumental demo)', length: '5:23', file: '5.Passed.mp3' }
]

const player = ref() as Ref<HTMLAudioElement>
const isPLaying = ref(false)
const currentTrack = ref(0)

const trackDuration = ref(0)
const currentTime = ref(0)

const currentTitle = computed(() => songs[currentTrack.value].title)
const currentDuration = computed(() => songs[currentTrack.value].length)
const currentSrc = computed(() => '/audio/' + songs[currentTrack.value].file)
const currentElapsed = computed(() => {
  let perc = 0
  if (trackDuration.value > 0) {
    perc = currentTime.value / trackDuration.value * 100
  }
  return `${perc}%`
})

function canPlay () {
  if (isPLaying.value) {
    player.value.play()
  }
}

function ended () {
  if (currentTrack.value < songs.length - 1) {
    next()
  } else {
    isPLaying.value = false
  }
}

function progress () {
  currentTime.value = player.value.currentTime
}

function durationChange () {
  trackDuration.value = player.value.duration
  currentTime.value = player.value.currentTime
  if (isPLaying.value) player.value.play()
}

function togglePlay () {
  if (isPLaying.value) {
    player.value.pause()
  } else {
    player.value.play()
  }
  isPLaying.value = !isPLaying.value
}

function playTrack (i: number) {
  currentTrack.value = i
  isPLaying.value = true
  player.value.play()
}

function next () {
  currentTrack.value = currentTrack.value >= songs.length - 1 ? 0 : currentTrack.value + 1
}

function prev () {
  currentTrack.value = currentTrack.value > 0 ? currentTrack.value - 1 : songs.length - 1
}

function setSongPosition (e:Event) {
  const evt = e as PointerEvent
  const targ = e.target as HTMLDivElement
  const perc = evt.offsetX / targ.clientWidth
  const newPos = player.value.duration * perc
  player.value.currentTime = newPos
}

</script>

<style lang="scss" scoped>
  .holder {
    background-color: #00000090;
    padding-bottom: 1px;

    &.mini {
      width: 100%;
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 8px;

      .controls {
        button {
          padding: 4px;

          svg {
            width: 16px;
          }
        }
      }
    }

    .cover {
      max-width: 100%;
    }

    .bg {
      background-color: #000000A0;
      border-radius: 5px;
      padding: 16px 24px;
      margin: 8px;
    }

    .controls {

      display: flex;
      align-items: center;
      justify-content: center;
      gap: 16px;

      .mini-cover img {
        max-width: 60px;
      }

      button {
        border-radius: 50%;
        padding: 8px;
        background-color: #000;
        border: 2px solid #CCC;
        cursor: pointer;

        svg {
          width: 16px;
        }

        &:hover {
          background-color: #222;
        }
      }
      .play {
        padding: 16px;
        svg {
          width: 32px;
        }
      }
      .progress-holder {
        flex-grow: 1;

        .current-title {
          font-size: 14px;
          padding: 8px;
          margin-top: -8px;
        }

        .progress {
          height: 8px;
          background-color: #333;
          cursor: pointer;

          .elapsed {
            width: 0%;
            height: 100%;
            background-color: #999;
          }
        }
      }
    }

    .playlist {
      ul {
        padding: 0;

        li {
          list-style-type: none;
          display: flex;
          cursor: pointer;

          .icon {
            width: 24px;
          }
          .title {
            flex-grow: 1;
          }

          &.active {
            background-color: #FFFFFF20;
          }

          &:hover {
            background-color: #222222A0;
          }
        }
      }
    }
  }
</style>
